import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import commonApi from '../utiles/api';

export const JournalContext = createContext();

export function JournalContextProvider({ children }) {

    const location = useLocation();
    const [addModal, setAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [globalSearch, setGlobalSearch] = useState('');
    const [journalData, setJournalData] = useState([]);
    const [journalDetail, setJournalDetail] = useState([]);
    const [filterInput, setFilterInput] = useState({
        tags: [],
        years: ['2024'],
    });

    const handleAddModals = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };
    const onLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('e-journal/front/list', filterInput);
            if (response.data.s === 1) {
                setJournalData(response?.data?.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onDetailApi = async (id) => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('e-journal/front/view', { id: id });
            if (response.data.s === 1) {
                setJournalDetail(response?.data?.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    // useEffect(() => {
    //     onLoadApi();
    // }, []);
    useEffect(() => {
        if (location?.state) {
            onDetailApi(location.state?.id);
        }
    }, [location]);
    const contextValue = {
        onLoadApi,
        filterInput,
        setFilterInput,
        journalData,
        journalDetail,
        isSearch,
        globalSearch,
        isLoading,
        handleAddModals,
        handleCloseModal,
        setAddModal,
        addModal,
        setIsLoading,
        setGlobalSearch,
        setIsSearch
    };

    return <JournalContext.Provider value={contextValue}>{children}</JournalContext.Provider>;
}
