import React, { createContext, useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { useLocation, useNavigate } from 'react-router-dom';
import commonApi from '../utiles/api';

export const CommonContext = createContext();

export function CommonContextProvider({ children }) {
    const navigate = useNavigate();
    const location = useLocation();
    const decryptData = (encryptedData, secretKey) => {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    };
    const handleLogout = () => {
        localStorage.removeItem('userIOV');
        localStorage.removeItem('iovToken');
        navigate('/login')
    };
    const secretKey = 'app-iov';
    const storedUserData = localStorage.getItem('userIOV');

    const [addModal, setAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [globalSearch, setGlobalSearch] = useState('');
    const [cityDropDown, setCityDropDown] = useState('');
    const [stateDropDown, setStateDropDown] = useState('');
    const [user, setUser] = useState(null);
    const [userPermission, setUserPermission] = useState(null);
    const [siteSettings, setSiteSettings] = useState('');
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
    const [isHovered, setIsHovered] = React.useState(false);
    const [seoData, setSeoData] = useState('');
    const [indexData, setIndexData] = useState('');
    const [isMobile, setIsMobile] = useState(false);


    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleAddModals = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };
    const onSeoApi = async () => {
        setIsLoading(true);
        try {
            const sanitizedPathname = location.pathname;
            const response = await commonApi.post('seo/front', {
                url: sanitizedPathname,
            });
            if (response.data.s === 1) {
                setSeoData(response.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onHomeIndexApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('home/index');
            if (response.data) {
                setIndexData(response.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onSiteSetting = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('site-settings/get');
            if (response.data.s === 1) {
                setSiteSettings(response.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (location.pathname !== '/') {
            onSeoApi();
        }
        onHomeIndexApi();
    }, [location]);
    useEffect(() => {
        if (storedUserData) {
            const parsedUser = decryptData(storedUserData, secretKey);
            if (parsedUser?.permissions) {
                setUserPermission(JSON.parse(parsedUser?.permissions))
            }
            setUser(parsedUser);
            // onSiteSetting();
        }
        // if (!storedUserData) {
        //     navigate('/login');
        // }
    }, [storedUserData]);
    const contextValue = {
        isMobile,
        indexData,
        setIndexData,
        onSeoApi,
        seoData,
        siteSettings,
        setSiteSettings,
        onSiteSetting,
        cityDropDown,
        setCityDropDown,
        stateDropDown,
        setStateDropDown,
        isHovered,
        setIsHovered,
        isSidebarOpen,
        setIsSidebarOpen,
        handleLogout,
        isSearch,
        globalSearch,
        user,
        userPermission,
        isLoading,
        handleAddModals,
        handleCloseModal,
        setAddModal,
        addModal,
        setIsLoading,
        setUser,
        setUserPermission,
        setGlobalSearch,
        setIsSearch
    };

    return <CommonContext.Provider value={contextValue}>{children}</CommonContext.Provider>;
}
