import React, { useContext, useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FaBars, FaTimes } from "react-icons/fa";
import "../../assetss/scss/Header.scss";
import Logo from "../../assetss/img/logo.png";
import { Link } from "react-router-dom";
import { CommonContext } from "../../context";
// import { Helmet } from "react-helmet-async";

const Header = () => {
  const { indexData } = useContext(CommonContext);
  const Logo =
    indexData &&
    indexData?.settings?.length > 0 &&
    indexData?.settings.find((item) => item.option_key === "logo")
      ?.option_value;
  const favicon =
    indexData &&
    indexData?.settings?.length > 0 &&
    indexData?.settings.find((item) => item.option_key === "favicon")
      ?.option_value;
  const siteTitle =
    indexData &&
    indexData?.settings?.length > 0 &&
    indexData?.settings.find((item) => item.option_key === "site_title")
      ?.option_value;
  const siteKeywords =
    indexData &&
    indexData?.settings?.length > 0 &&
    indexData?.settings.find((item) => item.option_key === "site_keywords")
      ?.option_value;
  const siteDescription =
    indexData &&
    indexData?.settings?.length > 0 &&
    indexData?.settings.find((item) => item.option_key === "site_desc")
      ?.option_value;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState({});
  const handleNavClick = (path) => {
    setActiveLink(path);
  };
  const [activeLink, setActiveLink] = useState("/");
  // New state for play/pause control
  const [isPaused, setIsPaused] = useState(false);

  //  Function to toggle play/pause

  const handlePlayPause = () => {
    setIsPaused((prev) => !prev);
  };

  useEffect(() => {
    if (favicon) {
      const faviconLink = document.querySelector("link[rel='icon']");
      if (faviconLink) {
        faviconLink.href = `${indexData?.path}/${favicon}`;
      }
      console.log("faviconLink", faviconLink);
    }
  }, [favicon]);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);


  const toggleSubMenu = (menu, isOpen) => {
    setIsSubMenuOpen((prev) => ({ ...prev, [menu]: isOpen }));
  };

  return (
    <>
      <div
        className={`overlay ${isMenuOpen ? "active" : ""}`}
        onClick={toggleMenu}
      ></div>

      <div className="top-strip">
        <div className={`top-strip-marquee ${isPaused ? "paused" : ""}`}>
          <div className="marquee-content">
            {indexData?.alerts?.title || "Welcome to our website!"}
          </div>
        </div>
        <Nav>
          <NavItem>
            <span className="play-pause-icon" onClick={handlePlayPause}>
              {isPaused ? "►" : "⏸"}
            </span>
            {/* <Link className="nav-link" to="/">
              Ekta
            </Link> */}
          </NavItem>
          {/* <NavItem>
            <Link className="nav-link" to="/page/tender">
              Tender
            </Link>
          </NavItem> */}
          <NavItem>
            <Link className="nav-link" to="/signin">
              Login
            </Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/signup">
              Register
            </Link>
          </NavItem>
        </Nav>
      </div>

      <Navbar color="light" light expand="md">
        <NavbarBrand href="/">
          <img src={`${indexData?.settings_path}/${Logo}`} alt="IOV Logo" className="logo" />
        </NavbarBrand>
        <div className="ml-auto d-md-none">
          <FaBars onClick={toggleMenu} className="menu-icon" />
        </div>
        <Nav className="ml-auto d-none d-md-flex" navbar>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/" ? "active" : ""}`} to="/" onClick={() => handleNavClick("/")}>Home</Link>
          </NavItem>
          <UncontrolledDropdown
            nav
            inNavbar
            onMouseEnter={() => toggleSubMenu("about", true)}
            onMouseLeave={() => toggleSubMenu("about", false)}
            isOpen={isSubMenuOpen["about"]}
          >
            <DropdownToggle nav caret>About</DropdownToggle>
            <DropdownMenu right>
              <DropdownItem><Link to="/about" className={`dropdown-item ${activeLink === '/about' ? 'active' : ''}`} onClick={() => handleNavClick("/about")}>Who We Are</Link></DropdownItem>
              <DropdownItem><Link to="/about-founder" className={`dropdown-item ${activeLink === '/about-founder' ? 'active' : ''}`} onClick={() => handleNavClick("/about-founder")}>About Founder</Link></DropdownItem>
              <DropdownItem><Link to="/about-secretary" className={`dropdown-item ${activeLink === '/about-secretary' ? 'active' : ''}`} onClick={() => handleNavClick("/about-secretary")}>Hon. Gen. Secretary Desk</Link></DropdownItem>
              <DropdownItem><Link to="/council" className={`dropdown-item ${activeLink === '/council' ? 'active' : ''}`} onClick={() => handleNavClick("/council")}>Council 2022-2024</Link></DropdownItem>
              <DropdownItem><Link to="/blue-book" className={`dropdown-item ${activeLink === '/blue-book' ? 'active' : ''}`} onClick={() => handleNavClick("/blue-book")}>Blue Book</Link></DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown
            nav
            inNavbar
            onMouseEnter={() => toggleSubMenu("join", true)}
            onMouseLeave={() => toggleSubMenu("join", false)}
            isOpen={isSubMenuOpen["join"]}
          >
            <DropdownToggle nav caret>Join IOV</DropdownToggle>
            <DropdownMenu right>
              <DropdownItem><Link to="/profile" className={`dropdown-item ${activeLink === '/profile' ? 'active' : ''}`} onClick={() => handleNavClick("/profile")}>Individual Membership</Link></DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/list-of-member" ? "active" : ""}`} to="/list-of-member" onClick={() => handleNavClick("/list-of-member")}>List Of Member</Link>
          </NavItem>
          <UncontrolledDropdown
            nav
            inNavbar
            onMouseEnter={() => toggleSubMenu("grievance", true)}
            onMouseLeave={() => toggleSubMenu("grievance", false)}
            isOpen={isSubMenuOpen["grievance"]}
          >
            <DropdownToggle nav caret>Grievance</DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <Link to="/cms/about-committee" className={`dropdown-item ${activeLink === '/cms/about-committee' ? 'active' : ''}`} onClick={() => handleNavClick("/cms/about-committee")}> About Committee </Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="/objective" className={`dropdown-item ${activeLink === '/objective' ? 'active' : ''}`} onClick={() => handleNavClick("/objective")}> Objective</Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/publications" ? "active" : ""}`} to="/publications" onClick={() => handleNavClick("/publications")}>Publications</Link>
          </NavItem>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/event" ? "active" : ""}`} to="/event" onClick={() => handleNavClick("/event")}>Event</Link>
          </NavItem>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/branches" ? "active" : ""}`} to="/branches" onClick={() => handleNavClick("/branches")}>Branches</Link>
          </NavItem>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/contact" ? "active" : ""}`} to="/contact" onClick={() => handleNavClick("/contact")}>Contact</Link>
          </NavItem>
        </Nav>
      </Navbar>
      <div className={`mobile-menu ${isMenuOpen ? "open" : ""}`}>
        <div className="mobile-menu-header">
          <img src={Logo} alt="IOV Logo" className="logo" />
          <FaTimes onClick={toggleMenu} className="close-icon" />
        </div>
        <Nav navbar>
          <NavItem>
            <Link className="nav-link" to="/" onClick={toggleMenu}>
              Home
            </Link>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle
              nav
              caret
              onMouseEnter={() => toggleSubMenu("about", true)}
              onMouseLeave={() => toggleSubMenu("about", false)}
            >
              About
            </DropdownToggle>
            <DropdownMenu
              right
              className={isSubMenuOpen["about"] ? "show" : ""}
            >
              <DropdownItem href="/about" onClick={toggleMenu}>
                Who We Are
              </DropdownItem>
              <DropdownItem href="/about-founder" onClick={toggleMenu}>
                About Founder
              </DropdownItem>
              <DropdownItem href="/about-secretary" onClick={toggleMenu}>
                Hon. Gen. Secretary Desk
              </DropdownItem>
              <DropdownItem href="/council" onClick={toggleMenu}>
                C ouncil 2022-2024
              </DropdownItem>
              <DropdownItem href="/blue-book" onClick={toggleMenu}>
                Blue Book
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle
              nav
              caret
              onMouseEnter={() => toggleSubMenu("join", true)}
              onMouseLeave={() => toggleSubMenu("join", false)}
            >
              Join IOV
            </DropdownToggle>
            <DropdownMenu right className={isSubMenuOpen["join"] ? "show" : ""}>
              <DropdownItem
                href="/profile"
                onClick={toggleMenu}
              >
                Individual Membership
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem>
            <Link
              className="nav-link"
              to="/list-of-member"
              onClick={toggleMenu}
            >
              List Of Member
            </Link>
          </NavItem>
          {/* <NavItem>
            <Link className="nav-link" to="/grievance" onClick={toggleMenu}>
              Grievance
            </Link>
          </NavItem> */}
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle
              nav
              caret
              onMouseEnter={() => toggleSubMenu("grievance", true)}
              onMouseLeave={() => toggleSubMenu("grievance", false)}
            >
              Grievance
            </DropdownToggle>
            <DropdownMenu right className={isSubMenuOpen["join"] ? "show" : ""}>
              <DropdownItem
                href="/profile"
                onClick={toggleMenu}
              >
                About Comity
              </DropdownItem>
              <DropdownItem
                href="/profile"
                onClick={toggleMenu}
              >
                Objective
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem>
            <Link className="nav-link" to="/publications" onClick={toggleMenu}>
              Publications
            </Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/event" onClick={toggleMenu}>
              Event
            </Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/branches" onClick={toggleMenu}>
              Branches
            </Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/contact" onClick={toggleMenu}>
              Contact
            </Link>
          </NavItem>
        </Nav>
      </div>
    </>
  );
};

export default Header;
